import { request } from '../http';
import { clientUrl, systemUrl, fileUrl } from '../baseURL';
const api = {
  /**
   * @interface /sysinfo/get
   * @methods get
   * @desc 查询系统配置
   * @param
   */
  getSystemConfig() {
    let params = {
      method: 'get',
      url: `${clientUrl}/sysinfo/get`
    };
    return request(params);
  },
  /**
   * @interface /homePage/homePageList
   * @methods get
   * @desc 行业动态
   * @param
   */
  getIndustryDynamicList(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/homePageList`,
      params: data
    };
    return request(params);
  },
  /**
   * @interface /index/getTopCount
   * @methods get
   * @desc 首页顶部数量
   * @param
   */
  getHomeTopNumberList(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/getTopCount`,
      params: data
    };
    return request(params);
  },

  //顶部数据  九个 

   /**
   * @interface /homePage/getExpertTopCount
   * @methods get
   * @desc 获取首页专家顶部数量统计
   * @param
   */
   getHomeExpertTopCount() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/getExpertTopCount`,      
    };
    return request(params);
  },
   /**
   * @interface /homePage/getIndustryStandardTopCount
   * @methods get
   * @desc 获取首页行业标准顶部数量统计
   * @param
   */
   getHomeIndustryStandardTopCount() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/getIndustryStandardTopCount`,      
    };
    return request(params);
  },
   /**
   * @interface /homePage/getLaboratoryTopCount
   * @methods get
   * @desc 获取首页实验室顶部数量统计
   * @param
   */
   getHomeLaboratoryTopCount() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/getLaboratoryTopCount`,      
    };
    return request(params);
  },
   /**
   * @interface /homePage/getNationalStandardTopCount
   * @methods get
   * @desc 获取首页国家标准顶部数量统计
   * @param
   */
   getHomeNationalStandardTopCount() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/getNationalStandardTopCount`,      
    };
    return request(params);
  },
   /**
   * @interface /homePage/getNewsTopCount
   * @methods get
   * @desc 获取首页资讯顶部数量统计
   * @param
   */
   getHomeNewsTopCount() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/getNewsTopCount`,      
    };
    return request(params);
  },
   /**
   * @interface /homePage/getPaperTopCount
   * @methods get
   * @desc 获取首页前沿技术顶部数量统计
   * @param
   */
   getHomePaperTopCount() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/getPaperTopCount`,      
    };
    return request(params);
  },
   /**
   * @interface /homePage/getPatentTopCount
   * @methods get
   * @desc 获取首页专利顶部数量统计
   * @param
   */
   getHomePatentTopCount() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/getPatentTopCount`,      
    };
    return request(params);
  },
   /**
   * @interface /homePage/getPolicyTopCount
   * @methods get
   * @desc 获取首页政策顶部数量统计
   * @param
   */
   getHomePolicyTopCount() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/getPolicyTopCount`,      
    };
    return request(params);
  },
   /**
   * @interface /homePage/getReportTopCount
   * @methods get
   * @desc 获取首页报告顶部数量统计
   * @param
   */
   getHomeReportTopCount() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/getReportTopCount`,      
    };
    return request(params);
  },

  /**
   * @interface /system/user/getInfo
   * @methods get
   * @desc 用户数据
   * @param
   */
  getCurrentUseInfo(data) {
    let params = {
      method: 'get',
      url: `${systemUrl}/user/getInfo`,
      params: data
    };
    return request(params);
  },
  /**
   * @interface /homePage/homePageFirmList
   * @methods get
   * @desc 首页重点企业列表
   * @param
   */
  getHomePageFirmList(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/homePageFirmList?industryType=${industryType}`,
    };
    return request(params);
  },
  /**
   * @interface /homePage/newsHotList
   * @methods get
   * @desc 最新动态 精选 资讯
   * @param
   */
  getHomeNewsHotList(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/newsHotList?industryType=${industryType}`
    };
    return request(params);
  },
  /**
   * @interface /homePage/paperHotList
   * @methods get
   * @desc 最新动态 精选 技术
   * @param
   */
  getHomePaperHotList(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/paperHotList?industryType=${industryType}`
    };
    return request(params);
  },
  /**
   * @interface /homePage/patentHotList
   * @methods get
   * @desc 最新动态 精选 专利
   * @param
   */
  getHomePatentHotList(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/patentHotList?industryType=${industryType}`
    };
    return request(params);
  },
  /**
   * @interface /homePage/policyHotList
   * @methods get
   * @desc 最新动态 精选 政策
   * @param
   */
  getHomePolicyHotList(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/policyHotList?industryType=${industryType}`
    };
    return request(params);
  },
  /**
   * @interface /user/profile
   * @methods put
   * @desc 设置用户信息
   * @param
   */
  setPersonInfo(data) {
    let params = {
      method: 'put',
      url: `${systemUrl}/user/profile`,
      data
    };
    return request(params);
  },
  /**
   * @interface /upload/head
   * @methods post
   * @desc 设置用户头像
   * @param
   */
  setPersonImg(data) {
    let params = {
      method: 'post',
      url: `${fileUrl}/upload/head`,
      data
    };
    return request(params);
  },
  /**
   * @interface /laboratory/findPageList
   * @methods get
   * @desc 获取实验室列表
   * @param
   */
  getLaboratory() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/homePageLaboratoryList`,
    };
    return request(params);
  },
  /**
   * @interface /homePage/homePageExpertList
   * @methods get
   * @desc 获取专家库列表
   * @param
   */
  getPeople() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/homePageExpertList`,
    };
    return request(params);
  },
  /**
   * @interface /homePage/homePageNationStandardList
   * @methods get
   * @desc 获取国家标准列表
   * @param
   */
  getNation() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/homePageNationStandardList`,
    };
    return request(params);
  },
  /**
   * @interface /homePage/homePageIndustryStandardList
   * @methods get
   * @desc 获取行业标准列表
   * @param
   */
  getIndustry() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/homePageIndustryStandardList`,
    };
    return request(params);
  },
  /**
   * @interface /homePage/homePageInternationalStandardList
   * @methods get
   * @desc 获取国际标准列表
   * @param
   */
  getInternational() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/homePageInternationalStandardList`,
    };
    return request(params);
  },
  /**
   * @interface /homePage/homePageForeignStandardList
   * @methods get
   * @desc 获取国外标准列表
   * @param
   */
  getForeign() {
    let params = {
      method: 'get',
      url: `${clientUrl}/homePage/homePageForeignStandardList`,
    };
    return request(params);
  },
};
export default api;
