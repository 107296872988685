import { request } from '../http';
import policy from './policy';
import information from './information';
import technology from './technology';
import report from './report';
import enterprise from './enterprise';
import { clientUrl } from '../baseURL';
const api = {
  ...policy, // 行业政策
  ...information, //行业资讯
  ...technology, //前沿技术
  ...enterprise, //重点企业
  ...report, //研究报告
  // 获取钢铁冶金顶部数量统计
  getSteelTopCount(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/metallurgyIndex/getTopCount?industryType=${industryType}`,
    };
    return request(params);
  }
};
export default api;
