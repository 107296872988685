import { createRouter, createWebHashHistory } from 'vue-router';
// import { findBread } from '@/utils/findBread';
import { useHomeStore } from '@/pinia/index';
// import HomeView from '../views/HomeView.vue';
// import home from '@/views/home/index.vue';

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: '首页',
    meta: {
      isShowAppHeader: true,
      isShowAppFooter: true
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/home/index.vue')
  },
  {
    path: '/pdfPreview/:id',
    name: 'pdf文件查看',
    meta: {
      isShowAppHeader: false,
      isShowAppFooter: false
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/components/pdf/pdfPreview.vue')
  },
  {
    path: '/permissionPdf',
    name: 'pdf权限查看',
    meta: {
      isShowAppHeader: false,
      isShowAppFooter: false
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ '@/components/pdf/permissionPdfShow.vue'
      )
  },
  {
    path: '/standard',
    name: '行业标准',
    meta: {
      isShowAppHeader: true,
      isShowAppFooter: true
    },
    component: () =>
      import(/* webpackChunkName: "standard" */ '@/views/standard/index.vue'),
    redirect: '/standard/country',
    children: [
      {
        path: '/standard/country',
        name: '国家标准',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "country" */ '@/views/standard/children/country.vue'
          )
      },
      {
        path: '/standard/industry',
        name: '行业标准',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "country" */ '@/views/standard/children/industry.vue'
          )
      },
      {
        path: '/standard/international',
        name: '国际标准',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "country" */ '@/views/standard/children/international.vue'
          )
      },
      {
        path: '/standard/abroad',
        name: '国外标准',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "country" */ '@/views/standard/children/abroad.vue'
          )
      },
      {
        path: '/standard/detail/:id',
        name: '标准详情',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "standard-detail" */ '@/views/standard/component/details.vue'
          )
      }
    ]
  },
  {
    path: '/industry',
    name: '行业领域',
    meta: {
      isShowAppHeader: false,
      isShowAppFooter: true
    },
    component: () =>
      import(/* webpackChunkName: "Industry" */ '@/views/industry/index.vue'),
    redirect: '/industry/main/:type',

    children: [
      {
        path: '/industry/main/:type',
        name: '领域首页',
        meta: {
          isShowAppHeader: false,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "IndustryMain" */ '@/views/industry/main/index.vue'
          )
      },
      {
        path: '/industry/policy/:type',
        name: '行业政策',
        meta: {
          isShowAppHeader: false,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "Policy" */ '@/views/industry/policy/index.vue'
          )
      },
      {
        path: '/industry/information/:type',
        name: '行业资讯',
        meta: {
          isShowAppHeader: false,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "Information" */ '@/views/industry/information/index.vue'
          )
      },
      {
        path: '/industry/technology/:type',
        name: '前沿技术',
        meta: {
          isShowAppHeader: false,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "Technology" */ '@/views/industry/technology/index.vue'
          )
      },
      {
        path: '/industry/enterprise/:type',
        name: '重点企业',
        meta: {
          isShowAppHeader: false,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "Enterprise" */ '@/views/industry/enterprise/index.vue'
          )
      },
      {
        path: '/industry/detail/:id',
        name: '领域详情',
        meta: {
          isShowAppHeader: false,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "Detail" */ '@/views/industry/detail/index.vue'
          )
      }
    ]
  },
  {
    path: '/plat',
    name: '科技平台',
    meta: {
      isShowAppHeader: true,
      isShowAppFooter: true
    },
    redirect: '/plat/instrumentFacility/instrument',
    component: () =>
      import(/* webpackChunkName: "plat" */ '@/views/plat/index.vue'),
    children: [
      {
        path: '/plat/instrumentFacility',
        name: '科研仪器设施共享服务平台',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        redirect: '/plat/instrumentFacility/instrument',
        component: () =>
          import(
            /* webpackChunkName: "instrumentFacility" */ '@/views/plat/instrumentFacility/index.vue'
          ),
        children: [
          {
            path: '/plat/instrumentFacility/instrument',
            name: '科研仪器',
            meta: {
              isShowAppHeader: true,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "instrument" */ '@/views/plat/instrumentFacility/instrument/index.vue'
              )
          },
          {
            path: '/plat/instrumentFacility/facilities',
            name: '科研设施',
            meta: {
              isShowAppHeader: true,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "facilities" */ '@/views/plat/instrumentFacility/facilities/index.vue'
              )
          },
          {
            path: '/plat/instrumentFacility/policySystem',
            name: '政策制度',
            meta: {
              isShowAppHeader: true,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "policySystem" */ '@/views/plat/instrumentFacility/policySystem/index.vue'
              )
          },
          {
            path: '/plat/instrumentFacility/instrumentMaintenance',
            name: '仪器维修',
            meta: {
              isShowAppHeader: true,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "instrumentMaintenance" */ '@/views/plat/instrumentFacility/instrumentMaintenance/index.vue'
              )
          },
          {
            path: '/plat/instrumentFacility/detail/:id',
            name: '仪器详情',
            meta: {
              isShowAppHeader: true,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "instrumentFacility-detail" */ '@/views/plat/instrumentFacility/detail/index.vue'
              )
          }
        ]
      },
      {
        path: '/plat/laboratory',
        name: '国家重点实验室',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "laboratory" */ '@/views/plat/laboratory/index.vue'
          )
      },
      {
        path: '/plat/laboratory/laboratoryContent',
        name: '实验室内容',
        meta: {
          isShowAppHeader: false,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "laboratory-content" */ '@/views/plat/laboratory/laboratory-content/laboratory-content.vue'
          ),
        redirect: '/plat/laboratory/introduction',
        children: [
          {
            path: '/plat/laboratory/introduction',
            name: '实验室简介',
            meta: {
              isShowAppHeader: false,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "laboratory-introduction" */ '@/views/plat/laboratory/laboratory-content/introduction/index.vue'
              )
          },
          {
            path: '/plat/laboratory/researchTeam',
            name: '研究队伍',
            meta: {
              isShowAppHeader: false,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "laboratory-researchTeam" */ '@/views/plat/laboratory/laboratory-content/researchTeam/index.vue'
              )
          },
          {
            path: '/plat/laboratory/researchDirection',
            name: '研究方向',
            meta: {
              isShowAppHeader: false,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "laboratory-researchDirection" */ '@/views/plat/laboratory/laboratory-content/researchDirection/index.vue'
              )
          },
          {
            path: '/plat/laboratory/researchTrends',
            name: '研究动态',
            meta: {
              isShowAppHeader: false,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "laboratory-researchTrends" */ '@/views/plat/laboratory/laboratory-content/researchTrends/index.vue'
              )
          },
          {
            path: '/plat/laboratory/OpenSubject',
            name: '开放课题',
            meta: {
              isShowAppHeader: false,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "laboratory-OpenSubject" */ '@/views/plat/laboratory/laboratory-content/OpenSubject/index.vue'
              )
          },
          {
            path: '/plat/laboratory/researchAchievement',
            name: '科研成果',
            meta: {
              isShowAppHeader: false,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "laboratory-researchAchievement" */ '@/views/plat/laboratory/laboratory-content/researchAchievement/index.vue'
              )
          },
          {
            path: '/plat/laboratory/detail/:id',
            name: '实验室详情',
            meta: {
              isShowAppHeader: false,
              isShowAppFooter: true
            },
            component: () =>
              import(
                /* webpackChunkName: "laboratory-detail" */ '@/views/plat/laboratory/laboratory-content/detail/index.vue'
              )
          }
        ]
      }
    ]
  },
  {
    path: '/major',
    name: '国外重大专项',
    meta: {
      isShowAppHeader: true,
      isShowAppFooter: true
    },
    component: () =>
      import(/* webpackChunkName: "Major" */ '@/views/major/index.vue')
  },
  {
    path: '/course',
    name: 'COURSE50',
    meta: {
      isShowAppHeader: true,
      isShowAppFooter: true
    },
    redirect: '/course/project',
    component: () =>
      import(/* webpackChunkName: "Major" */ '@/views/course/index.vue'),
    children: [
      {
        path: '/course/project',
        name: '项目简介',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "country" */ '@/views/course/project/index.vue'
          )
      },
      {
        path: '/course/technology',
        name: '技术开发',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "country" */ '@/views/course/technology/index.vue'
          )
      },
      {
        path: '/course/research',
        name: '研究成果',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "country" */ '@/views/course/research/index.vue'
          )
      },
      {
        path: '/course/details/:id',
        name: '研究成果详情',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "country" */ '@/views/course/research/details.vue'
          )
      }
    ]
  },
  {
    path: '/major/detail/:id',
    name: '详情页',
    meta: {
      isShowAppHeader: true,
      isShowAppFooter: true
    },
    component: () =>
      import(
        /* webpackChunkName: "Major" */ '@/views/major/children/detail.vue'
      )
  },
  {
    path: '/report',
    name: '研究报告',
    meta: {
      isShowAppHeader: true,
      isShowAppFooter: true
    },
    component: () =>
      import(/* webpackChunkName: "Report" */ '@/views/report/index.vue')
  },
  {
    path: '/resource',
    name: '资源中心',
    meta: {
      isShowAppHeader: true,
      isShowAppFooter: true
    },
    component: () =>
      import(/* webpackChunkName: "standard" */ '@/views/resources/index.vue'),
    redirect: '/resource/paper',
    children: [
      {
        path: '/resource/paper',
        name: '论文',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "standard" */ '@/views/resources/children/paper.vue'
          )
      },
      {
        path: '/resource/patent',
        name: '专利',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "standard" */ '@/views/resources/children/patent.vue'
          )
      },
      {
        path: '/resource/informations',
        name: '资讯',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "informations" */ '@/views/resources/children/informations.vue'
          )
      },
      {
        path: '/resource/standard',
        name: '标准',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "standard" */ '@/views/resources/children/standard.vue'
          )
      },
      {
        path: '/resource/association',
        name: '协会',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "association" */ '@/views/resources/children/association.vue'
          )
      },
      {
        path: '/resource/tool',
        name: '工具',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "tool" */ '@/views/resources/children/tool.vue'
          )
      },
      {
        path: '/resource/detail/:id',
        name: '资源详情',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "resource-detail" */ '@/views/resources/component/details.vue'
          )
      }
    ]
  },
  {
    path: '/expert',
    name: '专家库',
    meta: {
      isShowAppHeader: true,
      isShowAppFooter: true
    },
    component: () => import('@/views/expert/index.vue'),
    redirect: '/expert/people',
    children: [
      {
        path: '/expert/people',
        name: '专家列表',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "people" */ '@/views/expert/children/people.vue'
          )
      },
      {
        path: '/expert/dynamic',
        name: '院士动态',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "dynamic" */ '@/views/expert/children/dynamic.vue'
          )
      },
      {
        path: '/expert/detail/:id',
        name: '专家库详情',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "expert-detail" */ '@/views/expert/component/details.vue'
          )
      },
      {
        path: '/expert/paperDetail/:id',
        name: '论文详情',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "expert-detail" */ '@/views/expert/component/paperDetail.vue'
          )
      },
      {
        path: '/expert/dynamicDetail/:id',
        name: '动态详情',
        meta: {
          isShowAppHeader: true,
          isShowAppFooter: true
        },
        component: () =>
          import(
            /* webpackChunkName: "expert-detail" */ '@/views/expert/component/dynamicDetail.vue'
          )
      }
    ]
  },
  {
    path: '/person',
    name: '个人中心',
    meta: {
      isShowAppHeader: true,
      isShowAppFooter: true
    },
    component: () => import('@/views/person/person.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    redirect: '/404'
  },
  {
    path: '/404',
    name: '404',
    meta: {
      isShowAppHeader: false,
      isShowAppFooter: false
    },
    component: () => import('@/views/404.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  // let access = null;

  // if (document.cookie.includes('Authorization=')) {
  //   let cookieList = document.cookie.split('=');
  //   for (let i in cookieList) {
  //     if (cookieList[i].indexOf('Authorization') != -1) {
  //       access = document.cookie.split('Authorization=')[1];
  //     }
  //   }
  // }
  if (to.matched) {
    document.title = to.matched[to.matched.length - 1].name;
    useHomeStore().$state.currentHomeUrl = to.path;
  }
  next();

  // if (access) {
  //   if (to.matched) {
  //     document.title = to.matched[to.matched.length - 1].name;
  //     useHomeStore().$state.currentHomeUrl = to.path;
  //   }

  //   next();
  // } else {
  //   ElMessage({
  //     type: 'error',
  //     message: '请登录以后再操作！',
  //     duration: 700
  //   });
  //   next('/');
  // }

  // 有token
  // if (access) {
  //   next();
  //   // 直接放行
  // } else {
  //   // 否则是没有
  //   // 如果去的是登录页
  //   if (to.path === "/") {
  //     // 直接放行
  //     next();
  //   } else {
  //     // 如果去的是其他页,跳转到登录页
  //     ElMessage({
  //       message: '请登录以后再操作！',
  //       type: 'error'
  //     })
  //     next("/");
  //   }
  // }
});

export default router;
