import { request } from '../http';
import { clientUrl } from '../baseURL';
const api = {
  // -------------------------------重大专项欧盟基金
  // 获取欧盟基金顶部数量统计
  getProjectTopCount(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/project/getProjectTopCount`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取欧盟基金简介
  getIntroductionContent(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/introduction/getIntroduction`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金新闻分页列表查询
  getJournalPageList(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/journalism/getJournalismList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金会议分页列表查询
  getConferencePageList(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/conference/getConferenceList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目热词列表
  getMajorProjectHotSearch(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/projectyWord/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目技术组
  getMajorTechnicalGroup(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/projectClassify/technicalGroupList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目项目类型
  getMajorProjectType(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/projectClassify/projectTypeList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目项目类型
  getMajorProjectState(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/projectClassify/projectStateList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目项目年份
  getMajorTime(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/projectClassify/annualStatisticsList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目项目年份
  getMajorProjectPageList(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--时间趋势--项目数量
  getTimeTrendProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/timeTrendProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--时间趋势--资助基金（总额）
  getTimeTrendFoundationSumProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/timeTrendFoundationSumProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--时间趋势--资助基金（平均值）
  getTimeTrendFoundationAvgProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/timeTrendFoundationAvgProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--时间趋势--项目预算（总额）
  getTimeTrendBudgetSumProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/timeTrendBudgetSumProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--时间趋势--项目预算（平均值）
  getTimeTrendBudgetAvgProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/timeTrendBudgetAvgProject`,
      data: data,
      config: config
    };
    return request(params);
  },

  // 获取基金项目--技术组--项目数量
  getTechnicalGroupProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/technicalGroupProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--技术组--资助基金（总额）
  getTechnicalGroupFoundationSumProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/technicalGroupFoundationSumProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--技术组--资助基金（平均值）
  getTechnicalGroupFoundationAvgProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/technicalGroupFoundationAvgProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--技术组--项目预算（总额）
  getTechnicalGroupBudgetSumProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/technicalGroupBudgetSumProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--技术组--项目预算（平均值）
  getTechnicalGroupBudgetAvgProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/technicalGroupBudgetAvgProject`,
      data: data,
      config: config
    };
    return request(params);
  },

  // 获取基金项目--项目类型--项目数量
  getItemTypeProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/itemTypeProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--项目类型--资助基金（总额）
  getItemTypeFoundationSumProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/itemTypeFoundationSumProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--项目类型--资助基金（平均值）
  getItemTypeFoundationAvgProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/itemTypeFoundationAvgProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--项目类型--项目预算（总额）
  getItemTypeBudgetSumProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/itemTypeBudgetSumProject`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取基金项目--项目类型--项目预算（平均值）
  getItemTypeBudgetAvgProject(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/project/itemTypeBudgetAvgProject`,
      data: data,
      config: config
    };
    return request(params);
  },

  // /**
  //  * @interface /down/by/id
  //  * @methods get
  //  * @desc 根据ID 附件下载
  //  * @param id
  //  */

  // downLoadFileById(data) {
  //   let params = {
  //     method: 'get',
  //     url: `${clientUrl}/down/by/id?id=${data.id}`,
  //     responseType: 'blob'
  //     // headers: {
  //     //   Accept: 'application/octet-stream'
  //     // }
  //   };
  //   return request(params);
  // },
  /**
   * @interface /project/id
   * @methods get
   * @desc 基金项目详情
   * @param id
   */
  getFundDetail(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/project/${data.id}`
    };
    return request(params);
  },
  /**
   * @interface /journalism/browse
   * @methods get
   * @desc 基金新闻 浏览量次数
   * @param
   */
  addBrowserNums(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/journalism/browse?id=${data.id}`
    };
    return request(params);
  }
};
export default api;
