import { request } from '../http';
import { clientUrl } from '../baseURL';
const api = {
  // -------------------------------重点企业
  // 查询热词列表
  getEnterpriseHotSearch(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/patentWord/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 新增热词列表
  addEnterpriseHotSearch(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/patentWord/add`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取企业专利列表
  getAllFirmInPatent(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/patentClassify/firmNavigationList?industryType=${industryType}`,
      
    };
    return request(params);
  },
  // 获取企业资讯列表
  getAllFirmInNews(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/newsClassify/firmNavigationList?industryType=${industryType}`,     
    };
    return request(params);
  },
  // 获取重点企业--专利时间导航列表
  getAllTimeInEnterprise(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/patentClassify/timeNavigationList?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取重点企业--资讯时间导航列表
  getAllNewsTimeInInformation(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/newsClassify/timeNavigationFirmList?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取重点企业顶部数量统计
  getEnterpriseTopCount(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/patent/getPatentTopCount?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取重点企业--专利列表
  getEnterprisePageList(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/patent/getPatentPageList?pageSize=${data.pageSize}&pageNum=${data.pageNum}&industryType=${data.industryType}`,
      data: data,
      config: config
    };
    return request(params);
  },
  /**
   * @interface /patent/id
   * @methods get
   * @desc 重点企业 行业专利 详情
   * @param id
   */
  getEnterPriseDetailInfo(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/patent/${data.id}?industryType=${data.industryType}`
    };
    return request(params);
  },
  /**
   * @interface /patent/chart/timeSeries
   * @methods post
   * @desc 重点企业 专利时间序列
   * @param
   */
  getEnterPriseTimeEcharts(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/patent/chart/timeSeries`,
      data
    };
    return request(params);
  },
  /**
   * @interface /patent/chart/inventorNetwork
   * @methods post
   * @desc 重点企业 发明人网络
   * @param
   */
  getEnterPriseNetworkEcharts(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/patent/chart/inventorNetwork`,
      data
    };
    return request(params);
  },
  /**
   * @interface /paper/elastic/id
   * @methods post
   * @desc 重点企业 专利详情
   * @param id
   */
  getEnterPriseDetail(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/paper/getInfo/${data.id}?industryType=${data.industryType}`
    };
    return request(params);
  },
  /**
   * @interface /patent/chart/ipcNumber
   * @methods post
   * @desc 重点企业 分类号图表
   * @param
   */
  getEnterPriseClassifyEcharts(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/patent/chart/ipcNumber`,
      data
    };
    return request(params);
  }
};
export default api;
